import React, { useEffect, useState, useContext } from "react"

import PropTypes from "prop-types"

import Available from "../../assets/images/Icons/Available.svg"
import BlueLocker from "../../assets/images/Icons/blue-locker.svg"
import GreyLocker from "../../assets/images/Icons/locker.svg"
import WashingMachine from "../../assets/images/Icons/machine1.svg"
import WashingMachineLight from "../../assets/images/Icons/machine2.svg"
import StarLight from "../../assets/images/Icons/Star-light.svg"
import Star from "../../assets/images/Icons/Star.svg"
import Tick from "../../assets/images/Icons/tick.svg"
import WhiteLocker from "../../assets/images/Icons/white-locker.svg"
import WhiteMachine from "../../assets/images/Icons/white-machine.svg"
import WhiteTick from "../../assets/images/Icons/white-tick.svg"
import LogoLight from "../../assets/images/Logo-light.svg"
import Logo from "../../assets/images/Logo.svg"
import { AuthenticationContext } from "../../context"
import Link from "../../utils/link"
import "./LandingNew.scss"
import Faq from "../Application/Faq"

const LandingNew = ({ pageContext }) => {
  const locations = pageContext.locations
  const { setLocation } = useContext(AuthenticationContext)

  const [isMounted, setIsMounted] = useState(false)
  const [presentLoc, setPresentLoc] = useState({})
  const [isHover, setIsHover] = useState(false)
  const [isFaqOpen, setIsFaqOpen] = useState(false)

  useEffect(() => {
    localStorage.removeItem("loc")
    setIsMounted(true)
  }, [])

  const handleMouseEnter = (e) => {
    const locTemp = locations.find((item) => item.id === Number(e.target.id))
    setPresentLoc(locTemp)
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }

  useEffect(() => {
    if (isFaqOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "scroll"
    }
  }, [isFaqOpen])

  if (!isMounted) {
    return <div></div>
  }

  return (
    <div className="landing-new">
      <div className="landing-new__top">
        <div className="landing-new__container landing-new__container--top">
          <div className="landing-new__top__first-row">
            <img src={LogoLight} className="landing-new__top__logo" />

            <div className="landing-new__text landing-new__top__first-row__heading">
              <p>Self service laundromat</p>
            </div>

            <img src={Star} className="landing-new__top__first-row__star" />
            <p className="landing-new__text landing-new__top__first-row__text">
              <img src={StarLight} className="landing-new__text__star" />
              DROP OFF PICK UP
            </p>
            <img src={Star} className="landing-new__top__first-row__star" />
            <p className="landing-new__text landing-new__top__first-row__text">
              <img src={StarLight} className="landing-new__text__star" />
              IN APP PAY AND GO
            </p>
          </div>

          <div className="landing-new__top__second-row">
            <p className="landing-new__text">7 days / 7am — 11pm</p>
            <Link to="https://www.instagram.com/washroom_laundry/">
              <p className="landing-new__text">@washroom_laundry</p>
            </Link>
            <button
              className="landing-new__top__second-row__btn landing-new__text"
              onClick={() => setIsFaqOpen(true)}
            >
              FAQs
            </button>
            <Link to="/pricing" className="landing-new__top__second-row__btn">
              <p className="landing-new__text">Price List</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="landing-new__middle">
        <div className="landing-new__container">
          <img src={Logo} className="landing-new__middle__logo" />

          <div className="landing-new__middle__grid">
            {locations.map((place, index) => (
              <>
                <div key={index} className="location-text-container">
                  {!place.coming_soon ? (
                    <>
                      <Link to="/login">
                        <p
                          className="location-text"
                          id={locations[index].id}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => setLocation(place)}
                        >
                          {place.address}
                        </p>
                      </Link>
                      <div className="landing-new__icons">
                        <div className="landing-new__icons__icon">
                          <img
                            src={
                              place.has_washing_machine
                                ? WashingMachine
                                : WashingMachineLight
                            }
                            alt=""
                            className="locker"
                          />
                          <img
                            src={place.has_washing_machine ? Tick : Available}
                            alt=""
                            className="tick"
                          />
                        </div>
                        <div className="landing-new__icons__icon">
                          <img
                            src={place.has_lockers ? BlueLocker : GreyLocker}
                            alt=""
                            className="locker"
                          />
                          <img
                            src={place.has_lockers ? Tick : Available}
                            alt=""
                            className="tick"
                          />
                        </div>
                      </div>
                      <div className="landing-new__icons--mobile">
                        <div className="landing-new__icons__icon">
                          <img
                            src={
                              place.has_washing_machine
                                ? WhiteMachine
                                : WashingMachineLight
                            }
                            alt=""
                            className="locker"
                          />
                          <img
                            src={
                              place.has_washing_machine ? WhiteTick : Available
                            }
                            alt=""
                            className="tick"
                          />
                        </div>
                        <div className="landing-new__icons__icon">
                          <img
                            src={place.has_lockers ? WhiteLocker : GreyLocker}
                            alt=""
                            className="locker"
                          />
                          <img
                            src={place.has_lockers ? WhiteTick : Available}
                            alt=""
                            className="tick"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <p
                        className="location-text"
                        id={locations[index].id}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => setLocation(place)}
                      >
                        {place.address}
                      </p>
                      {place.coming_soon && (
                        <span>
                          <h3>lockers coming soon</h3>
                        </span>
                      )}
                      <div className="landing-new__icons">
                        <div className="landing-new__icons__icon">
                          <img
                            src={
                              place.has_washing_machine
                                ? WashingMachine
                                : WashingMachineLight
                            }
                            alt=""
                            className="locker"
                          />
                          <img
                            src={place.has_washing_machine ? Tick : Available}
                            alt=""
                            className="tick"
                          />
                        </div>
                        <div className="landing-new__icons__icon">
                          <img
                            src={place.has_lockers ? BlueLocker : GreyLocker}
                            alt=""
                            className="locker"
                          />
                          <img
                            src={place.has_lockers ? Tick : Available}
                            alt=""
                            className="tick"
                          />
                        </div>
                      </div>
                      <div className="landing-new__icons--mobile">
                        <div className="landing-new__icons__icon">
                          <img
                            src={
                              place.has_washing_machine
                                ? WhiteMachine
                                : WashingMachineLight
                            }
                            alt=""
                            className="locker"
                          />
                          <img
                            src={
                              place.has_washing_machine ? WhiteTick : Available
                            }
                            alt=""
                            className="tick"
                          />
                        </div>
                        <div className="landing-new__icons__icon">
                          <img
                            src={place.has_lockers ? WhiteLocker : GreyLocker}
                            alt=""
                            className="locker"
                          />
                          <img
                            src={place.has_lockers ? WhiteTick : Available}
                            alt=""
                            className="tick"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {isHover && (
                  <div
                    className="landing-new__middle__grid__image"
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    id={locations[index].id}
                  >
                    {!place.coming_soon ? (
                      <Link to={`/${presentLoc.slug}/get-started#login`}></Link>
                    ) : (
                      <img
                        src={presentLoc.main_photo_url}
                        alt="washroom building"
                      />
                    )}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="landing-new__bottom">
        <div className="landing-new__bottom__mob">
          <Link to="https://www.instagram.com/washroom_laundry/">
            <p className="landing-new__text">@washroom_laundry</p>
          </Link>
          <div className="landing-new__bottom__mob__flex">
            <p className="landing-new__text">7 days / 7am — 11pm</p>
            <img
              src={StarLight}
              className="landing-new__text__star"
              style={{ margin: 0 }}
              loading="lazy"
            />
          </div>
          <Link to="/pricing">
            <p className="landing-new__text">Price List</p>
          </Link>
          <button
            className="landing-new__bottom__mob__faq-btn landing-new__text"
            onClick={() => setIsFaqOpen(true)}
          >
            FAQs
          </button>
          <div className="landing-new__bottom__mob__email-link">
            <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
          </div>
        </div>
      </div>
      <div className="landing-new__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
      {isFaqOpen && <Faq setIsFaqOpen={setIsFaqOpen} />}
    </div>
  )
}

LandingNew.propTypes = {
  pageContext: PropTypes.any.isRequired,
}

export default LandingNew
