import React, { useState } from "react"

import propTypes from "prop-types"

import close from "../../assets/images/close-menu.svg"
import "./Faq.scss"
import SocialMedia from "./SocialMedia"

const Faq = ({ setIsFaqOpen }) => {
  const faqs = [
    {
      answer:
        "<p>Washroom Lockers bring laundromats into the modern age with a service tailor-made to fit into even the snuggest of schedules. Lockers can be found at all Washroom locations, providing customers with a streamlined alternative to the traditional self-service washroom experience.</p>",
      question: "What are Washroom Lockers?",
    },
    {
      answer:
        "<p>Our Lockers represent a network designed from the hem up with convenience in mind. It’s as seamless as heading to the Washroom website to choose your location, signing in to your account, selecting Drop Off and preferred wash types, then stowing your items in the designated locker. You’ll receive a text the following day when your items are ready for pick up, simply log back into your account and collect your items from the indicated locker.<br> In between, Washroom does all the legwork by whisking your laundry away to our warehouse, where it’s professionally tended to according to your needs (Laundry, Dry Cleaning, Wash and Press or Press only). From Cotton to Cashmere, Hi-Vis to Haute Couture, the staff at Washroom will launder all belongings equally with tender loving care.</p>",
      question: "How do Washroom Lockers work?",
    },
    {
      answer:
        "<p>Laundry dropped off before 6:00 p.m. will be available for pickup the following day, with the only exception being Saturday and Sunday drop-offs, which are available for pickup on Mondays.</p>",
      question:
        "How long will my clothes take to clean? (How soon can I expect my laundry to be ready?)",
    },
    {
      answer:
        "<p>Washroom Lockers are a contactless and carefree experience. For this reason, we only accept a credit card linked to your account.</p>",
      question: "Can I pay with cash?",
    },
  ]

  const [openedQuestion, setOpenedQuestion] = useState(null)

  return (
    <div className="faq-container">
      <div className="faq">
        <div className="faq__close">
          <img src={close} onClick={() => setIsFaqOpen(false)} />
        </div>

        <div className="faq__column1">
          <div className="faq__column1__top">
            <h6 className="faq__column1__heading">
              Frequently Asked Questions
            </h6>
          </div>
          <div className="faq__column1__bottom">
            <SocialMedia />
          </div>
        </div>

        <div className="faq__column2">
          <div className="faq__column2__questions">
            {faqs.map((faq, i) => (
              <div
                className={`faq__column2__questions__question ${
                  openedQuestion === i &&
                  "faq__column2__questions__question--opened"
                } `}
                onClick={() =>
                  openedQuestion === i
                    ? setOpenedQuestion(null)
                    : setOpenedQuestion(i)
                }
                key={i}
              >
                <div>
                  <h6 className="faq__column2__questions__question__text">
                    {faq.question}
                  </h6>
                  <img src={close} />
                </div>
                {openedQuestion === i && (
                  <div
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                    style={{ margin: 0 }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Faq.propTypes = {
  setIsFaqOpen: propTypes.func.isRequired,
}

export default Faq
